.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.react-spinner-loader-swing div {
  border-radius: 50%;
  float: left;
  height: 1em;
  width: 1em;
}
.react-spinner-loader-swing div:nth-of-type(1) {
  background: linear-gradient(to right, #385c78 0%, #325774 100%);
}
.react-spinner-loader-swing div:nth-of-type(2) {
  background: linear-gradient(to right, #325774 0%, #47536a 100%);
}
.react-spinner-loader-swing div:nth-of-type(3) {
  background: linear-gradient(to right, #4a5369 0%, #6b4d59 100%);
}
.react-spinner-loader-swing div:nth-of-type(4) {
  background: linear-gradient(to right, #744c55 0%, #954646 100%);
}
.react-spinner-loader-swing div:nth-of-type(5) {
  background: linear-gradient(to right, #9c4543 0%, #bb4034 100%);
}
.react-spinner-loader-swing div:nth-of-type(6) {
  background: linear-gradient(to right, #c33f31 0%, #d83b27 100%);
}
.react-spinner-loader-swing div:nth-of-type(7) {
  background: linear-gradient(to right, #da3b26 0%, #db412c 100%);
}
.react-spinner-loader-shadow {
  clear: left;
  padding-top: 1.5em;
}
.react-spinner-loader-shadow div {
  -webkit-filter: blur(1px);
  filter: blur(1px);
  float: left;
  width: 1em;
  height: .25em;
  border-radius: 50%;
  background: #e3dbd2;
}
.react-spinner-loader-shadow .react-spinner-loader-shadow-l {
  background: #d5d8d6;
}
.react-spinner-loader-shadow .react-spinner-loader-shadow-r {
  background: #eed3ca;
}
@-webkit-keyframes ball-l {
  0%, 50% {
    transform: rotate(0) translateX(0);
  }
  100% {
    transform: rotate(50deg) translateX(-2.5em);
  }
}
@keyframes ball-l {
  0%, 50% {
    transform: rotate(0) translateX(0);
  }
  100% {
    transform: rotate(50deg) translateX(-2.5em);
  }
}
@-webkit-keyframes ball-r {
  0% {
    transform: rotate(-50deg) translateX(2.5em);
  }
  50%,
  100% {
    transform: rotate(0) translateX(0);
  }
}
@keyframes ball-r {
  0% {
    transform: rotate(-50deg) translateX(2.5em);
  }
  50%,
  100% {
    transform: rotate(0) translateX(0)
  }
}
@-webkit-keyframes shadow-l-n {
  0%, 50% {
    opacity: .5;
    transform: translateX(0);
  }
  100% {
    opacity: .125;
    transform: translateX(-1.75em);
  }
}
@keyframes shadow-l-n {
  0%, 50% {
    opacity: .5;
    transform: translateX(0);
  }
  100% {
    opacity: .125;
    transform: translateX(-1.75em);
  }
}
@-webkit-keyframes shadow-r-n {
  0% {
    opacity: .125;
    transform: translateX(1.75em);
  }
  50%,
  100% {
    opacity: .5;
    transform: translateX(0);
  }
}
@keyframes shadow-r-n {
  0% {
    opacity: .125;
    transform: translateX(1.75em);
  }
  50%,
  100% {
    opacity: .5;
    transform: translateX(0);
  }
}
.react-spinner-loader-swing-l {
  -webkit-animation: ball-l .425s ease-in-out infinite alternate;
  animation: ball-l .425s ease-in-out infinite alternate;
}
.react-spinner-loader-swing-r {
  -webkit-animation: ball-r .425s ease-in-out infinite alternate;
  animation: ball-r .425s ease-in-out infinite alternate;
}
.react-spinner-loader-shadow-l {
  -webkit-animation: shadow-l-n .425s ease-in-out infinite alternate;
  animation: shadow-l-n .425s ease-in-out infinite alternate;
}
.react-spinner-loader-shadow-r {
  -webkit-animation: shadow-r-n .425s ease-in-out infinite alternate;
  animation: shadow-r-n .425s ease-in-out infinite alternate;
}


.react-spinner-loader-svg svg {
  transform-origin: 50% 65%;
}

.react-spinner-loader-svg svg polygon {
  stroke-dasharray: 17;
  -webkit-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
          animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 136;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 136;
  }
}
@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}


.react-spinner-loader-svg-calLoader {
  width: 230px;
  height: 230px;
  transform-origin: 115px 115px;
  -webkit-animation: 1.4s linear infinite loader-spin;
          animation: 1.4s linear infinite loader-spin;
}

.react-spinner-loader-svg-cal-loader__path {

  -webkit-animation: 1.4s ease-in-out infinite loader-path;

          animation: 1.4s ease-in-out infinite loader-path;
}

@-webkit-keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}
@keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}

